import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/export'

class ExportAccountingService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    getAuthUrl(id) {
        return http.get(`${this.getUrl()}/${id}/get-auth-url`)
    }
    handleCallback(data) {
        return http.post(`${this.getUrl()}/callback`, data)
    }
}

export default new ExportAccountingService()