<template>
    <div>
        <h1>{{status}}</h1>
    </div>
</template>

<script>
import exportAccountingService from '@/services/ExportAccountingService';

export default {
    data() {
        return {
            itemService: exportAccountingService,
            status: "Processing . . . "
        }
    },
    methods: {
        sendCallback(){
            if (this.$route.query.code && this.$route.query.state) {
                var data = {
                    'code': this.$route.query.code,
                    'state': this.$route.query.state
                }
                exportAccountingService.handleCallback(data).then(response => {
                    this.status = "Success";
                     this.$router.push({
                        path: '/nmtcompanysettings',
                        hash:  "#" + this.$route.query.state
                    });
                }).catch(error => {
                    this.status = "Error - Retry or contact support!";
                    console.log('error', error)
                })
            }
        }
    },
    mounted() {
        this.sendCallback();
    }
}
</script>