import http from '@/http-common'

export class ApiService {
    index(page, params) {
      var queryUrl = ''
      if (params && page) {
        const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
        queryUrl = `?page=` + page + `&` + queryParams
      }
        return http.get(`${this.getUrl()}` + queryUrl)
    }
    show(id) {
      return http.get(`${this.getUrl()}/${id}`)
    }
    store(data) {
      return http.post(`${this.getUrl()}`, data)
    }
    update(data) {
      return http.put(`${this.getUrl()}/${data.id}`, data)
    }
    delete(id) {
      return http.delete(`${this.getUrl()}/${id}`)
    }
    livesearch(query, field, selectFields = ['id'], extraOptions = [], sortField = null) {
      const sortBy = sortField ? sortField : field;
      var params = {
        "lazyEvent": {
          "sortField": this.hasRelation ? ('relation.' + sortBy) : sortBy,
          "sortOrder": sortBy == 'id' ? -1 : 1,
          "searchFrom": "livesearch",
          "searchType": "regex",
          "filters":{
            'global' :{
              "value": query,
              "matchMode":"contains"
            }
          },
          "selectFields": selectFields
        }
      };
      var selectfield = field.split('.');
      if(selectfield.length == 1 && this.hasRelation){
        params.lazyEvent.selectFields.push('relation_id');
        params.lazyEvent.selectFields.push({'relation': ['id', field]});
      }
      else if(selectfield.length == 1) {
        params.lazyEvent.selectFields.push(selectfield[0]);
      } else {
        var selectfieldObject = {}
        selectfieldObject[selectfield[0]] = ['id', selectfield[1]];
        params.lazyEvent.selectFields.push(selectfield[0] + "_id");
        params.lazyEvent.selectFields.push(selectfieldObject);
      }

      params.lazyEvent = JSON.stringify(params.lazyEvent);
      params = {...params, ...extraOptions};
      let queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
      const queryUrl = `?page=1&` + queryParams
      return http.get(`${this.getUrl()}` + queryUrl)
    }
}

export default new ApiService()